<template lang="html">
  <div class="renewal" v-if="detail">
    <div class="box">
      <div class="title">
        请确认续签信息
      </div>
      <div class="list">
        <div class="left">
          开始时间：
        </div>
        <div class="right">
          {{detail.contract.contract_start}}
        </div>
      </div>
      <!-- <div class="list">
        <div class="left">
          开始时间：
        </div>
        <div class="right">
          2020-08-17
        </div>
      </div> -->
      <div class="list">
        <div class="left">
          截止时间：
        </div>
        <div class="right">
          {{detail.contract.contract_end}}
        </div>
      </div>
      <!-- <div class="list">
        <div class="left">
          合同年限：
        </div>
        <div class="right">
          1年
        </div>
      </div> -->
      <div class="list">
        <div class="left">
          租赁商铺：
        </div>
        <div class="right">
          <span>{{detail.merchants_stall_number}}</span>
        </div>
      </div>
      <div class="list">
        <div class="left">
          商铺总租金：
        </div>
        <div class="right">
          {{detail.money || 0}}元
        </div>
      </div>
    </div>
    <div class="btns">
      <div class="cancel" @click="show = true">
        <van-button type="primary" color="#F8F8F8" round>
          <span style="color:#B9B9B9;">信息不对</span>
        </van-button>
      </div>
      <div class="affirm" @click="affirm">
        <van-button type="primary" round @click="$router.push({path : '/contract/contractSign', query: { id: id}})">确认</van-button>
      </div>
    </div>
    <van-popup v-model:show="show" round position="bottom" :closeable="true">
      <div class="queryBox">
        <div class="title">
          联系市场人员
        </div>
        <div class="list" v-for="item in listData" :key="item.user_id">
          <div class="listTitle">
            市场管理员：
          </div>
          <div class="content">
            <div class="name">
              {{item.name}} {{item.mobile}}
            </div>
            <div class="phone" @click="$common.callPhone(item.mobile)">
              立即拨打
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  export default {
    name: 'renewal',
    data() {
      return {
        show: false,
        id: '',
        detail: null,
        listData: []
      };
    },
    created() {
      this.id = this.$route.query.id;
      this.getDetail();
      this.getList();
    },
    methods: {
      getDetail() {
        this.$require.HttpPost('/h5/contract_stall/contract', { contract_id: this.id }).then(res => {
          this.detail = res.data;
        });
      },
      getList() {
        this.$require.HttpPost('/h5/user/userList', { pageNum: 1, pageSize: 100 }).then(res => {
          if (res.data.list) {
            let arr = [];
            for (let i in res.data.list) {
              if (res.data.list[i].management.role_worker_id.indexOf('1100000000') != -1) {
                arr.push(res.data.list[i]);
              }
            }
            this.listData = arr;
          }
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .renewal {
    height: calc(100vh - 30px);
    padding: 30px 24px 0 24px;
    background: url('../../assets/image/sekuai.png') no-repeat;
    background-size: 100% 150px;
    .box {
      background: #FFFFFF;
      box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
      border-radius: 30px;
      padding: 0 20px;
      color: #000000;
      .title {
        font-size: 32px;
        height: 100px;
        line-height: 100px;
        text-align: center;
      }
      .list {
        height: 105px;
        display: flex;
        font-size: 28px;
        align-items: center;
        border-bottom: 1px solid #EFEFEF;
        &:last-child {
          border-bottom: 0;
        }
        .left {
          min-width: 175px;
        }
        .right {
          color: #333333;
          span {
            margin-right: 30px;
          }
        }
      }
    }
    .btns {
      display: flex;
      justify-content: center;
      margin-top: 90px;
      .affirm,.cancel {
        width: 250px;
        height: 96px;
      }
      .cancel {
        margin-right: 60px;
      }
    }
    .affirmModel {
      width: 702px;
      height: 380px;
      background: #FFFFFF;
      .content {
        height: 202px;
        line-height: 202px;
        font-size: 32px;
        color: #000000;
        text-align: center;
      }
      .modelBtns {
        display: flex;
        justify-content: center;
        .affirm,.cancel {
          width: 250px;
          height: 96px;
        }
        .cancel {
          margin-right: 60px;
        }
      }
    }
    .queryBox {
      padding: 0 22px;
      .title {
        height: 112px;
        line-height: 112px;
        text-align: center;
        font-size: 32px;
        color: #000000;
      }
      .list {
        margin-bottom: 30px;
        .listTitle {
          font-size: 28px;
          color: #B2B2B2;
        }
        .content {
          display: flex;
          justify-content: center;
          align-items: center;
          .name {
            font-size: 28px;
            color: #000000;
            margin-right: 130px;
          }
          .phone {
            width: 160px;
            height: 60px;
            line-height: 60px;
            background: #F3F9E9;
            border-radius: 30px;
            font-size: 28px;
            color: #85C226;
            text-align: center;
          }
        }
      }
    }
  }
</style>
